/**
 * Created by BFR on 14/04/2022.
 */
define('configPrintAreaCollectionView',['module',
    'backbone',
    'underscore',
    'marionette',
    'configPrintAreaItemView'],
  function (module,
            Backbone,
            _,
            Marionette,
            ConfigPrintAreaItemView) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
      childView: ConfigPrintAreaItemView,

      childEvents: {
        'selected:printzone:change': 'onSelectedPrintZoneChange',
        'selected:printzone:image:change': 'onSelectedPrintZoneImageChange'
      },

      onSelectedPrintZoneChange: function (model, paId, pzId, nbrColor) {
        this.trigger('selected:printzone:change', paId, pzId, nbrColor);
      },

      onSelectedPrintZoneImageChange: function (model, paId, pzId, fileContent) {
        this.trigger('selected:printzone:image:change', paId, pzId, fileContent);
      },

        filter: function(model) {
          var display = false;
            if (model.get('printZones') && model.get('printZones').length > 0) {
                _.each(model.get('printZones'), function (pz) {
                    if (!pz.disablePz) {
                        display = true;
                    }
                });
            }
            return display;
        }
    });
  });
