/**
 * Created by RKL on 21/08/2015.
 */
define('productsListView',[
  'module',
  'jqgridView',
  'jquery',
  'app',
  'settings',
  'underscore',
  'template!productsListTpl',
  'productViewSwitcherView',
  'pricePopoverMixin',
  'rolesMixin'
], function (
  module,
  JqGridView,
  $,
  App,
  Settings,
  _,
  viewTpl,
  ViewSwitcherView,
  pricePopoverMixin,
  RolesMixin
) {
  'use strict';

  var ProductsView =  JqGridView.extend({
    template: viewTpl,
    filtersName: 'productsListFilters',
    regions: {
      viewSwitcher: '.js-view-switcher-region'
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        btnDelete: '.cell-delete'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click @ui.btnDelete': 'onDelete'
      }, this.gridEvents);
    },

    serializeData: function() {
      return {type: 'product'};
    },

    onShow: function() {
      this.getRegion('viewSwitcher').show(new ViewSwitcherView({
        currentView: 'table'
      }));
    },

    priceFormatter: function(cellValue, options) {
      if (cellValue === null || cellValue === undefined) { return ''; }

      return '<span class="cell-default" data-row-id="' +
          options.rowId + '"> 50: ' + cellValue.rsPrice50 + '€, 500: '+ cellValue.rsPrice500 +'€</span>';
    },

    onDelete: function(e) {
      var target = $(e.currentTarget),
        fontId = target.data('row-id');
      this.trigger('onDelete', fontId);
    },

    onGridFilterChange: function(e) {
        var that = this,
            filters = {},
            value;

        this.triggerClear(e);

        _.each(this.colModel, function(column) {
            if (column.search) {
                if (column.stype === 'select') {
                    value = that.$('select[name="' + column.index + '"]').val();
                    filters[column.index] = value === that.selectDefault ? '' : value;
                } else if (column.sorttype === 'price') {
                    value = that.$('input[name="' + column.index + '"]').val();
                    if (column.index === 'prices' && value) {
                        var p = pricePopoverMixin.parse(value);
                        filters.quantity = p.quantity;
                        filters.priceMin = p.priceMin;
                        filters.priceMax = p.priceMax;
                    }
                } else {
                    value = that.$('input[name="' + column.index + '"]').val();
                    filters[column.index] = value;
                }
            }
        });

        this.filters = filters;
        this.filterChange = true;
        this.updatePagination(true);
    },

    gridOptions: function(data) {
       var defaultsOptions, options;

      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {label: '', name: 'secId', key: true, hidden: true},
          {
            label: ' ',
            name: 'cataloguePictureUid',
            align: 'left',
            search: false,
            classes: 'product-link image-cell',
            formatter: this.pictureUidFormatter
          },
          {
            label: _.i18n('productList.productCode'),
            name: 'cCode',
            search: true,
            index: 'productCode',
            sorttype: 'integer',
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.productCodeMan'),
            name: 'baseCode',
            search: true,
            index: 'productCode',
            sorttype: 'integer',
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.productName'),
            name: 'name',
            search: true,
            index: 'productName',
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.productManufacturer'),
            name: 'manufacturerCode',
            search: true,
            index: 'manufacturer'/*, stype: 'select'*/,
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.status'),
            name: 'status',
            search: true,
            index: 'status',
            width: 60,
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('common.dtpLevel'),
            name: 'dtpLevel',
            classes: 'tickets-link',
            formatter:  this.checkBoxFormatter,
            search: true,
            index: 'dtpLevel',
            sortable: false,
            width: 130
          },
          {
            label: _.i18n('productList.toUpdateFromSupplier'),
            name: 'toUpdateFromSupplier',
            classes: 'tickets-link cell-to-be-checked',
            formatter: this.toUpdateFromSupplierFormatter,
            index: 'toUpdateFromSupplier',
            search: true,
            sortable: false,
            width: 60,
            stype: 'select',
            searchrules: {select: true},
            searchoptions: {
              value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
            }
          }
        ], 

        serializeGridData: function(postData) {
          if (postData.productCode) {
            postData.code = postData.productCode;
          }

          if (postData.productName) {
            postData.name = postData.productName;
          }

          if (postData.prices) {
            var p = pricePopoverMixin.parse(postData.prices);
            postData.quantity = p.quantity;
            postData.priceMin = p.priceMin;
            postData.priceMax = p.priceMax;
            delete postData.prices;
          } else {
            delete postData.quantity;
            delete postData.priceMin;
            delete postData.priceMax;
          }

          defaultsOptions.serializeGridData.apply(this, arguments);

          return postData;
        },

        onSelectRow: _.bind(function(rowid, a , event) {
          if (event && !$(event.target).hasClass('cell-delete') &&
              !$(event.target).hasClass('mdi-image-flip') &&
              event.type === 'click') {
            var index = 0; 
            for (var i = 1 ; i < event.currentTarget.rows.length ; i++) {
              var row = event.currentTarget.rows[i];
              if (row.id === rowid) {
                index = i - 1;
                break;
              }
            }
            var productListData = {
              filters: this.filters,
              rowCount: this.pagination.rowCount,
              currentIndex: (this.pagination.currentPage - 1) * this.pagination.pageSize + index
            };
            Settings.set('productListData', productListData);
            App.trigger('product:show', rowid);
          } else if (event && $(event.target).hasClass('mdi-image-flip') && event.type === 'click') {
            App.navigate('product/' + rowid + '/share', true);
          }

        }, this)
      };

      if (!Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        options.colModel.push({
          label: _.i18n('productList.outDated'),
          name: 'outDated',
          classes: 'tickets-link cell-to-be-checked',
          formatter: this.outDatedFormatter,
          index: 'outDated',
          search: true,
          sortable: false,
          width: 60,
          stype: 'select',
          searchrules: {select: true},
          searchoptions: {
            value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
          }
        });
        options.colModel.push({
          label: _.i18n('common.onOff'),
          name: 'showInWebshop',
          classes: 'tickets-link cell-to-be-checked',
          formatter: this.showInWebshopFormatter,
          index: 'showInWebshop',
          search: true,
          sortable: false,
          width: 60,
          stype: 'select',
          searchrules: {select: true},
          searchoptions: {
            value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.on') + ';' + false + ':' + _.i18n('common.off')
          }
        });
        options.colModel.push({
          label: _.i18n('productList.skipCompuzzData'),
          name: 'skipCompuzzData',
          classes: 'tickets-link cell-to-be-checked',
          formatter: this.skipCompuzzDataFormatter,
          index: 'skipCompuzzData',
          search: true,
          sortable: false,
          width: 60,
          stype: 'select',
          searchrules: {select: true},
          searchoptions: {
            value: ':' + _.i18n('common.all') + ';' + true + ':' + _.i18n('common.yes') + ';' + false + ':' + _.i18n('common.no')
          }
        });
      }

      options.colModel.push({
        label: '',
        name: '',
        classes: 'delete-link js-delete-font',
        formatter: this.deleteIconFormatter,
        search: false,
        width: 50
      });

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    toUpdateFromSupplierFormatter: function (cellvalue) {
      var htmlEl;
      if (cellvalue) {
        htmlEl = '<i class="mdi-image-flip"></i>';
      } else {
        htmlEl = '';
      }
      return htmlEl;
    },

    showInWebshopFormatter: function (cellvalue) {
      var htmlEl;
      if (cellvalue) {
        htmlEl = '<i class="mdi-hardware-computer" title="' + _.i18n('common.onOff') + '"></i>';
      } else {
        htmlEl = '';
      }
      return htmlEl;
    },

    skipCompuzzDataFormatter: function (cellvalue) {
      var htmlEl;
      if (cellvalue) {
        htmlEl = '<i class="mdi-file-cloud-off" title="' + _.i18n('productList.skipCompuzzData') + '"></i>';
      } else {
        htmlEl = '';
      }
      return htmlEl;
    }
  });

  module.exports = ProductsView;
});

