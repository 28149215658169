
define('template!configPrintAreaItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label class="col-xs-4 control-label">\n  ' +
((__t = ( code )) == null ? '' : __t) +
' - ' +
((__t = ( name )) == null ? '' : __t) +
' (' +
((__t = ( width )) == null ? '' : __t) +
'x' +
((__t = ( height )) == null ? '' : __t) +
'mm)\n</label>\n<div class="dropdown col-xs-8">\n  <button class="form-control dropdown-toggle dropdown__button" type="button"\n          id="dropdownMenu-pz"\n          data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n    <span class="pull-left dropdown__label printzone-label">None</span>\n    <span class="caret pull-right m-t-5 dropdown__caret"></span>\n  </button>\n  <ul class="dropdown-menu dropdown-menu-printzone" role="menu"\n      aria-labelledby="dropdownMenu-pz">\n    <li role="presentation"><a role="menuitem" data-index="" data-pz-id="-1" href="#">None</a>\n    </li>\n    ';
 for (var j = 0 ; j < printZones.length ; j++) { ;
__p += '\n    ';
 var pz = printZones[j]; ;
__p += '\n    ';
 if (!pz.disablePz) { ;
__p += '\n    ';
 if (pz.colorModeType === 'spot') { ;
__p += '\n    ';
 for (var k = 1 ; k <= pz.maxColors ; k ++) { ;
__p += '\n    <li role="presentation"><a role="menuitem" data-index=""\n                               data-nb-color="' +
((__t = ( k )) == null ? '' : __t) +
'"\n                               data-pz-id="' +
((__t = ( pz.id )) == null ? '' : __t) +
'"\n                               href="#">' +
((__t = ( pz.name )) == null ? '' : __t) +
' (' +
((__t = ( k )) == null ? '' : __t) +
') (' +
((__t = ( pz.width )) == null ? '' : __t) +
'x' +
((__t = ( pz.height
      )) == null ? '' : __t) +
'mm) (' +
((__t = (
      _.i18n('printZone.printedBy') + ' ' + pz.printerName )) == null ? '' : __t) +
')</a></li>\n    ';
 } ;
__p += '\n    ';
 } else { ;
__p += '\n    <li role="presentation"><a role="menuitem" data-index=""\n                               data-nb-color="1"\n                               data-pz-id="' +
((__t = ( pz.id )) == null ? '' : __t) +
'"\n                               href="#">' +
((__t = ( pz.name )) == null ? '' : __t) +
' (' +
((__t = ( pz.width )) == null ? '' : __t) +
'x' +
((__t = ( pz.height )) == null ? '' : __t) +
'mm) (' +
((__t = (
      _.i18n('printZone.printedBy') + ' ' + pz.printerName )) == null ? '' : __t) +
')</a></li>\n    ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    ';
 } ;
__p += '\n  </ul>\n</div>\n<div class="col-xs-offset-4 col-xs-8">\n  <img class="image-selected" src="" style="display: none;"/>\n</div>\n<div class="row upload-image-block" style="display: none;">\n  <div class="col-xs-offset-4 col-xs-8">\n    <div class="image-holder js-image-holder pgallery_item upload-image-button">\n      <input id="browseAttachFile" multiple type="file" name="filename" accept="image/*"\n             class="hidden attach-input js-change-logo"/>\n      <div class="bootstrap-filestyle">\n        <label class="js-logo-holder">\n          <i class="mdi-action-perm-media icon"></i>\n          <i class="mdi-content-add abs-icon-addon"></i>\n        </label>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

